


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import backendUrl from '../../../environment';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import trucksImage2 from '../../../Assets/trucksImage3.jpg';
import trucksImage4 from '../../../Assets/trucksImage6.png';
import searchinterfacesymbol from '../../../Assets/search-interface-symbol.png'
import repairingOnStand from '../../../Assets/repairingonstand.jpg'
import advocateprotest from '../../../Assets/advocateprotest.png'
import mechanicuser from '../../../Assets/mechanicuser.png'
import garageuser from '../../../Assets/garageuser.png'
import cranetruckuser from '../../../Assets/cranetruckuser.png'
import { useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import crossUser from '../../../Assets/crossUser.png'
import emergencycall from '../../../Assets/emergencycall.png'
import nearbyRestaurant from '../../../Assets/nearbyRestaurant.png'
import nearbyhospital from '../../../Assets/nearbyhospital.png'
import nearbyPetrolPump from '../../../Assets/nearbyPetrolPump.png'
import nearbyParking from '../../../Assets/nearbyParking.png'
import checksuccess from '../../../Assets/checksuccess.png'
import processImgUser from '../../../Assets/processImgUser.png'
import filterUser from '../../../Assets/filterUser.png'

import truckrepairingUser from '../../../Assets/truckrepairingUser.png'
import repairUser from '../../../Assets/repairUser.png'
import BottomNavigationBar from '../BottomNavigationBar.jsx';
import NoDataFound from '../Cards/NoDataFound.jsx';
import Registration from '../../Registration/Registration.jsx';
import Modal from '../../Location1/Modal.jsx';
import Loading from '../Cards/Loading.jsx';





export default function AllVehicles() {
    const [data, setData] = useState([]);
    const [accidentData, setAccidentData] = useState([]);
    const [filtering, setFiltering] = useState([]);

    const [notAccidentVehicleData, setNotAccidentVehicleData] = useState([]);
    const [accidentVehicleData, setAccidentVehicleData] = useState([]);


    const [currentItem, setCurrentItem] = useState([]);
    const [viewDetails, setViewDetails] = useState(false);
    const [caseDetailsHere, setCaseDetailsHere] = useState(false);
    const [viewAllVendors, setViewAllVendors] = useState(false);

    const [doneFetching, setDoneFetching] = useState(false)
    const [doneFetching2, setDoneFetching2] = useState(false)
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [filter, setFilter] = useState('')
    const currentService = 'crane'



    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate()

    const getFilteredData = (filter) => {
        console.log("data is here");
        const filteredData = [];

        const now = new Date();  // Current date and time
        const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
        const yesterday = new Date(now.getTime() - oneDay); // Yesterday's date and time
        const weekBefore = new Date(now.getTime() - (oneDay * 7));
        const monthBefore = new Date(now.getTime() - (oneDay * 30));




        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const todayDate = formatDate(now);
        const yesterdayDate = formatDate(yesterday);
        const weekBeforeDate = formatDate(weekBefore)
        const monthBeforeDate = formatDate(monthBefore)

        console.log("todayDate", todayDate)
        console.log("yesterdayDate", yesterdayDate)


        for (let i = 0; i < accidentData.length; i++) {
            let getTime = accidentData[i]?.systemDate.split('|');
            let assignedDate = getTime[0];
            let assignedTime = getTime[1];
            let assignedDateTime = new Date(`${assignedDate} ${assignedTime}`);

            if (filter === 'daily') {
                console.log("here i am daily")
                if (assignedDate === todayDate || assignedDate === yesterdayDate) {
                    const timeDifference = now - assignedDateTime;
                    if (timeDifference <= oneDay) {
                        console.log("Match found within last 24 hours:", data[i]);
                        filteredData.push(accidentData[i]);
                    }
                }
            }
            else if (filter === 'weekly') {
                console.log("here i am weekly")
                if (assignedDateTime >= weekBefore && assignedDateTime <= now) {
                    console.log("Match found within last 7 days:", data[i]);
                    filteredData.push(accidentData[i]);
                }
            }
            else if (filter === 'monthly') {
                if (assignedDateTime >= monthBefore && assignedDateTime <= now) {
                    console.log("Match found within last 30 days:", data[i]);
                    filteredData.push(accidentData[i]);
                }
            }
            else if (filter === 'year') {
                const yearBefore = new Date(now.getTime() - (oneDay * 365)); // Calculate date one year ago
                if (assignedDateTime >= yearBefore && assignedDateTime <= now) {
                    console.log("Match found within last year:", accidentData[i]);
                    filteredData.push(accidentData[i]);
                }
            }
        }
        if (filter === 'newest') {
            console.log("Sorting by newest to oldest");
            accidentData.sort((a, b) => {
                const dateA = new Date(a?.systemDate.split('|').join(' '));
                const dateB = new Date(b?.systemDate.split('|').join(' '));
                return dateB - dateA; // Descending order
            });
            setData([...accidentData]);
        } else if (filter === 'oldest') {
            console.log("Sorting by oldest to newest");
            accidentData.sort((a, b) => {
                const dateA = new Date(a?.systemDate.split('|').join(' '));
                const dateB = new Date(b?.systemDate.split('|').join(' '));
                return dateA - dateB; // Ascending order
            });
            setData([...accidentData]);
        }
        else {
            setData(filteredData)
        }
    };

    useEffect(() => {
        getData();
        getAccidentData()
        console.log("token", token, userId);
        if (token === "" || userId === "") {
            navigate("/");
        }
    }, [token, userId, navigate]);

    const goToNewCase = () => {
        navigate("/register-new-accidentvehicle", { state: { fromPageHere: "allVehicles", vehicleNo: currentItem.vehicleNo } })
    }

    const getData = async (e) => {
        console.log("userid", userId);
        let accidentVehicles = [], notAccidentVehicles = []
        const response = await axios.get(`${backendUrl}/api/getPersonalVehicleInfoById/${userId}`);
        if (response.data.message == "No accident vehicle data found.") {
            setData([])
            setDoneFetching(true)
        }
        else {
            console.log("response123421", response.data.data);
            response.data.data.map((item) => {
                if (item.alreadyAccidentVehicle == true) accidentVehicles.push(item)
                if (item.alreadyAccidentVehicle == false) notAccidentVehicles.push(item)

            })
            console.log("seTDATIOATN", response.data.data);
            setFiltering(accidentVehicles)
            setData(accidentVehicles)
            setNotAccidentVehicleData(notAccidentVehicles)
            setAccidentVehicleData(accidentVehicles)
            setDoneFetching(true)
        }
    };

    const getAccidentData = async (e) => {
        console.log("userid", userId);
        const response = await axios.get(`${backendUrl}/api/getPersonalAccidentVehicleInfoById/${userId}`);
        if (response.data.message == "No accident vehicle data found.") {
            setAccidentData([])
            setDoneFetching2(true)
        }
        else {
            setAccidentData(response.data.data)
            setDoneFetching2(true)
            console.log("ACcidenData", accidentData)
        }
    };

    const setDetails = (item) => {
        setViewDetails(true)
        setCurrentItem(item)
    }

    const setUpdateAccidentData = (item) => {
        setCaseDetailsHere(true)
        console.log(item.vehicleNo)
        const gotItem = accidentData.find((accidentItem) => {
            console.log(item.vehicleNo, accidentItem.reg)

            return (accidentItem.reg == item.vehicleNo)
        })
        console.log("GOTITEM", gotItem)
        setCurrentItem(gotItem)
    }

    const setViewVendors = (item) => {
        setViewAllVendors(true)
        const gotItem = accidentData.find((accidentItem) => {
            return (accidentItem.reg == item.vehicleNo)
        })
        console.log("hey", gotItem)
        setCurrentItem(gotItem)
    }

    const handleUpdate = () => {
        setCaseDetailsHere(false)
    };

    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        const newRows = filtering.filter((row) => {
            const formattedId = String(row.id).padStart(4, '0').toLowerCase(); // Make sure the formatted ID is lowercase
            const searchLower = value; // Use the updated search value directly

            const idValue = formattedId.includes(searchLower);
            const vehicleNoValue = (row.vehicleNo ?? '').toLowerCase().includes(searchLower);
            const chassisNoValue = (row.chassisNo ?? '').toLowerCase().includes(searchLower);

            return vehicleNoValue || chassisNoValue;
        });

        setData(newRows);
    };
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleSelect = (index) => {
        setSelectedIndex(index);
        index == 0 ? setFiltering(accidentVehicleData) : setFiltering(notAccidentVehicleData)
        index == 0 ? setData(accidentVehicleData) : setData(notAccidentVehicleData)
    };

    const getOnPage = (service) => {
        console.log("SOME", service)
        if (currentItem?.[`${service}Details`]?.customerAcceptedVendor == true && currentItem?.[`${service}Details`].confirmDoneWorking == false) navigate("/Crane-dashboard", { state: { indexFor: 0, service: service, vehicleNumber: currentItem.reg } })
        else if (currentItem?.[`${service}Details`]?.customerAcceptedVendor == false) navigate("/Crane-dashboard", { state: { indexFor: 1, service: service, vehicleNumber: currentItem.reg } })
        else if (currentItem?.[`${service}Details`]?.customerAcceptedVendor == true && currentItem?.[`${service}Details`].confirmDoneWorking == true) navigate("/Crane-dashboard", { state: { indexFor: 2, service: service, vehicleNumber: currentItem.reg } })
    }

    const settingFilter = (filter) => {
        console.log("filter", filter)
        setFilter(filter)
        getFilteredData(filter)
        setOpenFilterModal(false)
    }

    return (
        <div style={{marginBottom:"60px", background: 'linear-gradient(rgb(223 255 222 / 0%), rgb(255, 255, 255), rgb(255, 255, 255))' }}>
            {doneFetching2 && doneFetching && (
                <div style={{marginBottom:"40px"}}>
                    <div style={{ position: "sticky", top: "14px", zIndex: "1000" }}>
                        <div className="imageContainer" style={{ height: "0px" }}>
                            {["Accident Vehicles", "Other Vehicles"].map((text, index) => (
                                <div
                                    key={index}
                                    style={{ cursor: 'pointer' }}
                                    className={`imageWrapper ${selectedIndex === index ? "selected" : ""}`}
                                    onClick={() => handleSelect(index)}
                                >
                                    <div className="top-scrolling">
                                        <p>{text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div style={{
                        marginBottom: "100px", background: 'linear-gradient(rgb(181 235 178), rgb(255 255 255), rgb(255, 255, 255))',
                    }}>
                        <div className="container" style={{
                            // paddingTop:"30px",
                            maxWidth: "500px",
                            height: "30px",
                            marginBottom: "30px",
                            paddingTop: "30px"
                            // filter: isImageContainerVisible ? "blur(4px)" : "none", // Apply blur effect
                            // opacity: isImageContainerVisible ? 0.5 : 1, // Reduce opacity if blurred
                            // pointerEvents: isImageContainerVisible ? "none" : "auto", // Disable clicking
                        }}>
                            <div className="d-flex justify-content-center h-100">
                                <div className="searchbar" style={{ border: '1px solid', minWidth: "300px" }}>
                                    <input className="search_input" type="text" placeholder="Search..." onChange={handleSearch} />
                                    {/* <a href="#" className="search_icon">
                            <i className="fas fa-search"></i>
                            </a> */}
                                    <img src={searchinterfacesymbol} className="search_icon" style={{ height: '15px', width: '15px' }} alt='search' />

                                </div>
                               {selectedIndex == 0 &&(
                                 <div style={{ margin: "23px 20px 0px" }}>
                                    <img src={filterUser} style={{ height: '20px', width: "20px" }} onClick={() => setOpenFilterModal(!openFilterModal)} />
                                </div>
                            )}
                            </div>
                        </div>
                        {selectedIndex == 0 && (<p style={{ fontWeight: "bold", margin: "70px 20px 20px 30px" }}><em> All Accident Vehicles</em></p>)}
                        {selectedIndex == 1 && (<p style={{ fontWeight: "bold", margin: "70px 20px 20px 30px" }}><em> All Registered Vehicles</em></p>)}



                        <div style={{ marginBottom: "30px" }}>
                            {data.length > 0 && (
                                data.map((item, index) => (
                                    <div key={index} style={{
                                        // filter: isImageContainerVisible ? "blur(3px)" : "none", // Apply blur effect
                                        // opacity: isImageContainerVisible ? 0.9 : 1, // Reduce opacity if blurred
                                        // pointerEvents: isImageContainerVisible ? "none" : "auto",
                                        border: "1px solid teal",
                                        minWidth: "280px",
                                        margin: '10px',
                                        boxShadow: 'rgba(0, 0, 0, 0.2) 3px 4px 12px 8px',
                                        borderRadius: "20px 20px 0 0",
                                        padding: "10px",
                                        maxWidth: "410px",
                                        background: "white"
                                    }}>

                                        <div>
                                            <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                                                <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Vehicle No:</p>
                                                <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>{item.vehicleNo}</span>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                                                <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Chassis No:</p>
                                                <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>{item.chassisNo}</span>
                                            </div>
                                           
                                            
                                        </div>



                                        <div style={{ display: "flex", alignItems: "center", margin: '3px 5px 0px 10px' }}>
                                            {/* <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Current Status:</p> */}
                                            {/* <span style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "5px",
                                    padding: "3px 10px",
                                    fontSize: "12px",
                                    borderRadius: "10px",
                                    color: 'blue',
                                    border: "1px solid green",
                                    background: 'white'
                                }}>In Repair</span> */}
                                        </div>

                                        {selectedIndex == 1 && (<div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                            <p style={{
                                                fontSize: '11px',
                                                marginTop: "5px",
                                                background: "#62ff00a6",
                                                padding: "10px",
                                                border: '1px solid blue',
                                                textAlign: 'center',
                                                borderRadius: '30px',
                                                fontWeight: "bold",
                                                color: "black",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "center",
                                                position: "relative",
                                                cursor: "pointer",
                                                margin: '5px 5px 5px 5px',
                                                maxWidth: "400px",
                                                minWidth: "280px",
                                            }} onClick={() => setDetails(item)}>
                                                <KeyboardDoubleArrowRightIcon style={{
                                                    position: "absolute",
                                                    left: '10px'
                                                }} />
                                                View More Details
                                            </p>
                                        </div>)}
                                        {selectedIndex == 0 && (<div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                            <p style={{
                                                fontSize: '11px',
                                                marginTop: "5px",
                                                background: "#62ff00a6",
                                                padding: "10px",
                                                border: '1px solid blue',
                                                textAlign: 'center',
                                                borderRadius: '30px',
                                                fontWeight: "bold",
                                                color: "black",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "center",
                                                position: "relative",
                                                cursor: "pointer",
                                                margin: '5px 5px 5px 5px',
                                                maxWidth: "400px",
                                                minWidth: "140px",
                                            }} onClick={() => setUpdateAccidentData(item)}>
                                                <KeyboardDoubleArrowRightIcon style={{
                                                    position: "absolute",
                                                    left: '10px'
                                                }} />
                                                Accident Details
                                            </p>
                                            <p style={{
                                                fontSize: '11px',
                                                marginTop: "5px",
                                                padding: "10px",
                                                border: '1px solid blue',
                                                textAlign: 'center',
                                                borderRadius: '30px',
                                                fontWeight: "bold",
                                                color: "black",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "center",
                                                position: "relative",
                                                cursor: "pointer",
                                                margin: '5px 5px 5px 5px',
                                                maxWidth: "400px",
                                                minWidth: "140px",
                                            }} onClick={() => setViewVendors(item)}>
                                                <KeyboardDoubleArrowRightIcon style={{
                                                    position: "absolute",
                                                    left: '10px'
                                                }} />
                                                View Vendors
                                            </p>
                                        </div>)}
                                    </div>
                                ))
                            )}
                            {data.length == 0 && (
                                <NoDataFound />
                            )}
                        </div>
                        {viewDetails && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                                    zIndex: 1000,
                                    display: "flex",
                                    alignItems: "flex-end", // positions the container at the bottom
                                    justifyContent: "center",
                                    animation: "slideUp 0.5s ease-out",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: "97%",
                                        maxWidth: "600px",
                                        backgroundColor: "#fff", // white background for the content
                                        borderRadius: "15px 15px 0px 0px",
                                        maxHeight: "80%", // limit the height for scrollability
                                        overflowY: "auto", // enables vertical scrolling
                                        padding: "20px 5px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                                    }}
                                >
                                    <img
                                        src={crossUser}
                                        onClick={() => setViewDetails(false)}
                                        style={{
                                            position: "fixed",
                                            left: "calc(100% - 35px)",
                                            width: "25px",
                                            height: "25px",
                                            cursor: "pointer",
                                            zIndex: 1001,
                                            filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))",
                                        }}
                                    />
                                    <div style={{
                                        backgroundColor: "rgb(255 255 255)",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        margin: "30px 5px 30px 5px",
                                        boxShadow: "rgba(0, 0, 0, 0.1) -20px -18px 10px 0px",
                                        borderRadius: "10px"
                                    }}>

                                        {[
                                            { label: "Vehicle No", value: currentItem.vehicleNo, icon: "fa-car", color: "green" },
                                            { label: "Chassis No", value: currentItem.chassisNo, icon: "fa-id-card", color: "blue" },
                                            { label: "Engine No", value: currentItem.engineNo, icon: "fa-cogs", color: "orange" },
                                            { label: "Make Of Vehicle", value: currentItem.make, icon: "fa-industry", color: "purple" },
                                            { label: "Model Of Vehicle", value: currentItem.model, icon: "fa-tasks", color: "red" },
                                            { label: "Year Of Vehicle", value: currentItem.year, icon: "fa-calendar-alt", color: "brown" },
                                            { label: "Type Of Vehicle", value: currentItem.type, icon: "fa-truck", color: "teal" },
                                            { label: "Application Of Vehicle", value: currentItem.application, icon: "fa-wrench", color: "darkblue" },
                                            { label: "Insurance Of Vehicle", value: currentItem.InsuranceName, icon: "fa-shield-alt", color: "gold" },
                                        ].map((item, index) => (
                                            <div>
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        margin: "10px 0",
                                                        padding: "10px 10px 0px 15px",
                                                        // backgroundColor: "#f9f9f9",
                                                        borderRadius: "8px",
                                                        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    }}
                                                >
                                                    <i
                                                        className={`fas ${item.icon}`}
                                                        style={{
                                                            fontSize: "20px",
                                                            color: item.color,
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    <p style={{ fontSize: "14px", fontWeight: "bold", margin: 0 }}>{item.label}:</p>
                                                    <span style={{ color: item.color, marginLeft: "8px", fontSize: "14px" }}>{item.value}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ marginBottom: "50px", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <p style={{
                                            fontSize: '13px',
                                            marginTop: "10px",
                                            background: "green",
                                            padding: "10px",
                                            border: '1px solid blue',
                                            textAlign: 'center',
                                            borderRadius: '30px',
                                            fontWeight: "bold",
                                            color: "white",
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: "center",
                                            position: "relative",
                                            minWidth: "70%",
                                            cursor: "pointer"
                                        }} onClick={goToNewCase} >
                                            Register as accident vehicle
                                            <KeyboardDoubleArrowLeftIcon style={{
                                                position: 'absolute',
                                                right: "10px"
                                            }} />
                                            <KeyboardDoubleArrowRightIcon style={{
                                                position: 'absolute',
                                                left: "10px"
                                            }} />
                                        </p>
                                    </div>
                                </div>


                            </div>
                        )}

                        {caseDetailsHere && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                                    zIndex: 1000,
                                    display: "flex",
                                    alignItems: "flex-end", // positions the container at the bottom
                                    justifyContent: "center",
                                    animation: "slideUp 0.5s ease-out",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: "97%",
                                        maxWidth: "600px",
                                        backgroundColor: "#fff", // white background for the content
                                        borderRadius: "15px 15px 0px 0px",
                                        // marginBottom: "30px",
                                        maxHeight: "90%", // limit the height for scrollability
                                        overflowY: "auto", // enables vertical scrolling
                                        padding: "20px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                                    }}
                                >
                                    <img
                                        src={crossUser}
                                        onClick={() => {
                                            setCaseDetailsHere(false)
                                        }}
                                        style={{
                                            position: "fixed",
                                            left: "calc(100% - 35px)",
                                            width: "25px",
                                            height: "25px",
                                            cursor: "pointer",
                                            zIndex: 1001,
                                            filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))",
                                        }}
                                    />

                                    <Registration item={currentItem} fromPageHere={"allvehicles"} onUpdated={handleUpdate} />
                                </div>
                            </div>
                        )}
                        {viewAllVendors && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                                    zIndex: 1000,
                                    display: "flex",
                                    alignItems: "flex-end", // positions the container at the bottom
                                    justifyContent: "center",
                                    animation: "slideUp 0.5s ease-out",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: "97%",
                                        maxWidth: "600px",
                                        backgroundColor: "#fff", // white background for the content
                                        borderRadius: "15px 15px 0px 0px",
                                        // marginBottom: "30px",
                                        maxHeight: "90%", // limit the height for scrollability
                                        overflowY: "auto", // enables vertical scrolling
                                        padding: "20px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                                    }}
                                >
                                    <img
                                        src={crossUser}
                                        onClick={() => {
                                            setViewAllVendors(false)
                                        }}
                                        style={{
                                            position: "fixed",
                                            left: "calc(100% - 35px)",
                                            width: "25px",
                                            height: "25px",
                                            cursor: "pointer",
                                            zIndex: 1001,
                                            filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))",
                                        }}
                                    />
                                    <div style={{ textAlign: "center", margin: "30px 0", flexDirection: "column", display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                        {currentItem.selectedOptions.split(",").map((item) => {
                                            return (
                                                <div key={item} onClick={() => { getOnPage(item) }} style={{ display: 'flex', justifyContent: 'space-between', color: "darkgreen", fontWeight: "bold", marginBottom: "20px", fontSize: "15px", border: "1px solid red", background: "#ffffffa6", minWidth: "200px", borderRadius: "20px", padding: "10px" }}>
                                                    <p style={{ marginTop: "3px" }}> {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}</p>
                                                    {currentItem?.[`${item}Details`]?.confirmDoneWorking == true ? (<img src={checksuccess} style={{ height: "20px", width: "20px" }} />) : (<img src={processImgUser} style={{ height: "20px", width: "20px" }} />)}
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        )}

                        <Modal isOpen={openFilterModal} onClose={() => setOpenFilterModal(!openFilterModal)}>
                            {openFilterModal && (
                                <div style={{ textAlign: "center", marginTop: "30px", flexDirection: "column", display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                    <p style={{ color: "#000000", fontWeight: "bold", marginBottom: "10px", fontSize: "15px", border: "1px solid red", background: "rgb(0 243 122 / 65%)", minWidth: "200px", borderRadius: "20px", padding: "7px" }} onClick={() => { settingFilter('newest') }}>newest to oldest</p>
                                    <p style={{ color: "#000000", fontWeight: "bold", marginBottom: "10px", fontSize: "15px", border: "1px solid red", background: "rgb(0 243 122 / 65%)", minWidth: "200px", borderRadius: "20px", padding: "7px" }} onClick={() => { settingFilter('oldest') }}>oldest to newest</p>
                                    <p style={{ color: "#000000", fontWeight: "bold", marginBottom: "10px", fontSize: "15px", border: "1px solid red", background: "rgb(0 243 122 / 65%)", minWidth: "200px", borderRadius: "20px", padding: "7px" }} onClick={() => { settingFilter('daily') }}>Yesterday</p>
                                    <p style={{ color: "#000000", fontWeight: "bold", marginBottom: "10px", fontSize: "15px", border: "1px solid red", background: "rgb(0 243 122 / 65%)", minWidth: "200px", borderRadius: "20px", padding: "7px" }} onClick={() => { settingFilter('weekly') }}>Last 7 days</p>
                                    <p style={{ color: "#000000", fontWeight: "bold", marginBottom: "10px", fontSize: "15px", border: "1px solid red", background: "rgb(0 243 122 / 65%)", minWidth: "200px", borderRadius: "20px", padding: "7px" }} onClick={() => { settingFilter('monthly') }}>Last 30 days</p>
                                    <p style={{ color: "#000000", fontWeight: "bold", marginBottom: "10px", fontSize: "15px", border: "1px solid red", background: "rgb(0 243 122 / 65%)", minWidth: "200px", borderRadius: "20px", padding: "7px" }} onClick={() => { settingFilter('year') }}>Year</p>
                                </div>
                            )}
                        </Modal>
                    </div>
                </div>
            )}

            {doneFetching2 == false && doneFetching && (
                <Loading />
            )}
            <div>
                <BottomNavigationBar />
            </div>
        </div>
    )
}